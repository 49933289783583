.player-submission {
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__box {
    width: 35%;
    min-height: 120px;
    max-height: 120px;
    padding: 15px;
    background-color: var(--color-black);
    color: var(--color-white);
    border: 1px solid var(--color-white);
    border-radius: 10px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    overflow: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &__picture {
      min-width: 150px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        max-height: 125px;
        max-width: 125px;
        border: 1px solid var(--color-white);
        border-radius: 10px;
        margin-left: 10px;
      }
    }

    &__info {
      width: 75%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &-name {
        padding: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14pt;
        font-weight: bold;
      }

      &-all-teams {
        max-height: 100px;
        margin-left: 10px;
        font-size: 9pt;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-wrap: wrap;
      }
    }

    &__submittedBy {
      position: absolute;
      color: var(--color-blue);
    }

    &__submittedBy.left {
      margin-right: 50%;
      color: var(--color-green);
    }

    &__submittedBy.right {
      margin-left: 50%;
      color: var(--color-red);
    }
  }

  .connected-player {
    border: 1px solid var(--color-green);
  }

  &__connection {
    width: 50%;
    min-height: 198px;
    max-height: 198px;
    color: var(--color-green);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    &__teams-container {
      max-width: 40%;
      min-width: 200px;
      margin: 40px;
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid var(--color-green);
      border-radius: 10px;
      background-color: var(--color-black);
      color: var(--color-green);
      font-size: 11pt;
      z-index: 101;
    }

    &__line {
      position: absolute;
      max-width: 1px;
      min-height: 198px;
      max-height: 198px;
      border-left: 1px solid var(--color-green);
      z-index: 100;
    }
  }

  top: 0;
  transition: top 1s;

  top: 0;
  animation-name: fadeInFromTop, fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;

  opacity: 1;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 1s;
}
