@import '../../styles/vars.scss';

.player-search-result {
  position: absolute;
  width: 50%;
  padding: 5px;
  margin-left: 10px;
  background-color: var(--color-white);
  color: var(--color-gray);
  border: 1px solid var(--color-gray);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;

  &__name {
    margin-left: 5px;
    font-size: 10pt;
    font-weight: bold;

    &__years {
      margin-left: 10px;
      font-size: 9pt;
      font-weight: normal;
    }

    .disallowed {
      text-decoration: line-through;
    }
  }

  &__submit {
    font-size: 9pt;
    background-color: var(--color-red);
    color: var(--color-black);
    padding: 2px 10px;

    &:hover {
      cursor: pointer;
      background-image: linear-gradient(rgba(0, 0, 0, 0.1) 0 0);
    }
  }

  .disallowed-btn {
    background-color: var(--color-gray);
    &:hover {
      cursor: not-allowed;
      background-image: none;
    }
  }
}
