@import '../../styles/vars.scss';

.game {
  height: 100vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  .game-container {
    display: flex;
    height: 100%;
    width: 100%;

    &__summary-container {
      width: 45%;
      padding: 10px;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      &__header {
        &__title {
          padding: 5px;
          font-size: 32pt;
        }

        &__subtitle {
          padding: 5px;
          font-size: 14pt;
        }
      }

      &__target-player {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        &__name {
          min-height: 50px;
          width: 100%;
          text-align: center;
          font-size: 24pt;
        }

        &__info {
          margin-top: 3px;
          font-size: 12pt;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;

          img {
            min-height: 190px;
            max-height: 190px;
            min-width: 125px;
            max-width: 190px;
            border-radius: 10px;
            border: 2px solid var(--color-black);
          }

          &-item {
            margin-top: 3px;
          }
        }
      }

      &__teammates {
        margin-top: 20px;
        display: flex;

        &-teammate {
          padding: 10px 25px;
        }
      }
    }

    &__guess-container {
      width: 55%;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__guesses {
        height: 50%;
        width: 95%;
        position: relative;

        &__pass-container {
          width: 100%;
          display: flex;
          justify-content: space-between;

          &__pass {
            font-size: 14pt;

            &:hover {
              color: var(--color-red);
              cursor: pointer;
            }
          }
        }

        &__guess {
          width: 100%;
          min-height: 50px;
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 2px;
          font-size: 11pt;
          background-color: var(--color-black);
          color: var(--color-white);
          border-radius: 5px;
          border: 2px solid var(--color-black);
          transition: margin 0.5s;
          overflow-x: visible;
          white-space: nowrap;

          opacity: 1;
          animation-name: fadeInOpacity;
          animation-iteration-count: 1;
          animation-timing-function: ease-in;
          animation-duration: 0.5s;
        }

        .correct-guess {
          background-color: var(--color-green);
          color: var(--color-black);
        }

        .passed-guess {
          background-color: var(--color-gray);
        }
      }
    }
  }

  ::ng-deep .MuiBox-root {
    background-color: blue;
  }
}

.game-loading {
  height: 100vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__spinner {
    border-color: var(--color-gray) !important;
    border-bottom: transparent !important;
    width: 150px !important;
    height: 150px !important;
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
