@import '../../styles/vars.scss';

.player-guess {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;

  &__name {
    width: 25%;
    text-align: start;
  }

  &__teammate {
    width: 25%;
    color: var(--color-black);
    text-align: center;

    span:hover {
      cursor: pointer;
    }

    .MuiTooltip-tooltip {
      text-wrap: wrap;
      font-size: 10pt;
      font-family: Oswald;
    }
  }

  .correct-guess {
    color: var(--color-black);
  }

  .all-shared-teams {
    color: var(--color-green);
  }

  .some-shared-teams {
    color: yellow;
  }

  .no-shared-teams {
    color: var(--color-red);
  }
}
