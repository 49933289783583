@import 'styles/vars.scss';

.App {
  text-align: center;
  display: flex;
}

a {
  color: var(--color-teal);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: var(--color-teal);
  }
}

.user-loading {
  height: 100vh;
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;

  &__spinner {
    border-color: var(--color-gray) !important;
    border-bottom: transparent !important;
    width: 150px !important;
    height: 150px !important;
  }
}
